<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        justify="center"
        align-self="center"
        align="center"
        class="text-h5 mb-5"
      >
        NONSTOPNÍK
      </v-col>
    </v-row>
    <v-row
      no-gutters
      justify="space-around"
      class="pa-0 ma-0"
    >
      <v-col
        class="pa-0 ma-0"
        cols="12"
        sm="6"
        md="5"
      >
        <v-img
          v-if="!$vuetify.breakpoint.smAndDown"
          class="blob"
          height="450px"
          src="@/assets/fichee.svg"
        ></v-img>

        <v-img
          v-else
          class="blob"
          height="400px"
          src="@/assets/fichee.svg"
        ></v-img>
      </v-col>
    </v-row>

    <v-row justify="space-between">
      <v-col
        v-if="isAuthenticated"
        cols="0"
        md="1"
      >
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="search"
          label="Vyhľadávanie v témach"
        ></v-text-field>
      </v-col>

      <v-col
        v-if="isAuthenticated"
        align="right"
        cols="12"
        md="1"
        class="mb-sm-5 mb-xs-5"
      >
        <v-btn
          v-if="filter_favorite"
          text
          color="amber amber-darken-4"
          @click="filter_favorite = false"
        >
          Obľúbené &nbsp;<v-icon>mdi-star</v-icon>
        </v-btn>

        <v-btn
          v-else
          text
          color="amber amber-darken-4"
          @click="filter_favorite = true"
        >
          Obľúbené &nbsp;<v-icon>mdi-star-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      v-if="homeTilesNews.length > 0"
      justify="center"
      align="center"
      class="pt-3 mt-3"
    >
      <v-col
        v-for="tile in homeTilesNews"
        v-bind:key="tile.id"
        cols="12"
        sm="6"
        md="4"
        class="d-flex pt-0 mt-0"
        style="flex-direction:column"
      >
        <v-card
          class="ma-1 d-flex align-center justify-center pt-0 mt-0"
          height="150"
          :color="tile.color"
          dark
          :to="getLink(tile)"
        >
          <v-card-title class="text-center text-h4">
            <v-row no-gutters>
              <v-col
                v-show="tile.icon"
                cols="12"
              ><v-icon large>{{tile.icon}}</v-icon></v-col>
              <v-col cols="12">
                {{tile.short_title}} <span
                  v-show="tile.unvoted_count > 0"
                  class="text-h5"
                >&nbsp;({{ tile.unvoted_count }})</span>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-if="homeTiles.length > 0"
      justify="center"
      align="center"
    >
      <v-col
        v-for="tile in homeTiles"
        v-bind:key="tile.id"
        cols="12"
        sm="6"
        md="4"
        class="d-flex"
        style="flex-direction:column"
      >
        <v-card
          class="ma-1 d-flex align-center justify-center"
          height="100"
          :color="tile.color"
          dark
          :to="getLink(tile)"
        >
          <v-container>
            <v-row
              no-gutters
              justify="space-between"
            >
              <v-col
                v-if="isAuthenticated"
                cols="1"
              >
              </v-col>
              <v-col
                class="text-center"
                v-show="tile.icon"
                cols="10"
              ><v-icon>{{tile.icon}}</v-icon>
              </v-col>
              <v-col
                v-if="isAuthenticated"
                cols="1"
                align="right"
              >
                <v-btn
                  v-if="tile.user_favorite"
                  icon
                  color="amber amber-darken-4"
                  @click.prevent="removeFavorite(tile.id)"
                ><v-icon>mdi-star
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  icon
                  color="amber amber-darken-4"
                  @click.prevent="addFavorite(tile.id)"
                ><v-icon>mdi-star-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                align-self="center"
                class="text-center text-h6"
                cols="12"
              >{{tile.short_title}}
                <span
                  v-show="tile.unvoted_count > 0"
                  class="text-body-1"
                >&nbsp;({{ tile.unvoted_count }})</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  
  <script>
import { mapGetters } from "vuex";
import { APIService } from "@/http/APIService.js";

const apiService = new APIService();
export default {
  name: "Home",

  data() {
    return {
      homeTiles: [],
      homeTilesNews: [],
      search: "",
      sort: ["order"],
      count: 0,
      countNews: 0,
      filter_favorite: false,
    }
  },

  mounted: function () {
    this.$store.dispatch("menu/fetchMenu");
    this.$store.dispatch("settings/fetchSettings");
    this.$store.dispatch("menu/fetchAdminNewQuestions");
    this.getVerified();
    this.getHomeTiles(1);
    this.getHomeTiles(0);
  },


  watch: {
    filter_favorite() {
      this.getHomeTiles(0);
    },

    search() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.getHomeTiles(1);
        this.getHomeTiles(0);
      }, 500);
    },
  },

  computed: {
    ...mapGetters("auth", ["isAuthenticated", "username"]),
    ...mapGetters("settings", ["getSettings"]),
  },

  methods: {
    removeFavorite(id) {
      apiService.generalPollCategoryRemoveFavorite(id).then(() => {
        this.getHomeTiles(0);
      });
    },

    addFavorite(id) {
      apiService.generalPollCategoryAddFavorite(id).then(() => {
        this.getHomeTiles(0);
      });
    },

    getLink(item) {
      if (item.url)
        return item.url;
      else {
        return `/poll_category/${item.id}/`;
      }
    },

    getHomeTiles(news) {
      let limit = 0;
      let offset = 0;
      let filters = [];

      if (this.filter_favorite)
        filters.push(['favorite', 1]);

      filters.push(['news', news]);


      apiService.getGeneralPollCategories(this.sort, this.search, limit, offset, filters).then((response) => {
        if (news) {
          this.homeTilesNews = response.results;
          this.countNews = response.count;
        } else {
          this.homeTiles = response.results;
          this.count = response.count;
        }
      });
    },
    getHeight() {

      // let el = this.$refs.card4.$el;
      // console.log(el);

      // let cs = getComputedStyle(el);

      // let tallColumnHeight = el.clientHeight;
      // tallColumnHeight -= parseFloat(cs.paddingTop) + parseFloat(cs.paddingBottom);

      // this.height = tallColumnHeight + "px";

      return this.getSettings.homepage_vyska + "px";
    },
    getVerified() {
      if (this.isAuthenticated) {
        apiService.getMe().then((response) => {
          this.$store.commit("auth/verified", response.verified);
          this.$store.commit("auth/verified_sms_email", response.verified_sms_email);
          this.$store.commit("auth/verified_bank", response.verified_bank);
          this.$store.commit("auth/staff", response.is_staff);
          this.$store.commit("auth/moderator", response.moderator);
          this.$store.commit("auth/setMemberStatus", response.member_status);
          this.$store.commit("auth/setFirstName", response.first_name);
          this.$store.commit("auth/setLastName", response.last_name);
          this.$store.commit("auth/setGender", response.gender);
          this.$store.commit("auth/user_id", response.id);
        });
      }
    },
  },
};
  </script>
  
  <style scoped>
.blob {
  animation: pulse 1.1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  15% {
    transform: scale(1.15);
  }

  30% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.1);
  }

  80% {
    transform: scale(1);
  }
}
</style>